
.card-img-top {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
}
.w_overlay {
	background:rgba(0,0,0,0.5);
	width:100%;
	height:100%;
	position:absolute;
	padding:5%;
	-webkit-transition: all 0.4s ease-out 0s;
	-moz-transition: all 0.4s ease-out 0s;
	-ms-transition: all 0.4s ease-out 0s;
	-o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    text-align: center;
}
.w_overlay h3 {
	color:white;
	margin-top:5%;
	-webkit-transition: all 0.4s ease-out 0s;
	-moz-transition: all 0.4s ease-out 0s;
	-ms-transition: all 0.4s ease-out 0s;
	-o-transition: all 0.4s ease-out 0s;
	transition: all 0.4s ease-out 0s;
    margin-top: 20%;
}
.w_overlay a {
	opacity:0;
	-webkit-transition: all 0.4s ease-out 0s;
	-moz-transition: all 0.4s ease-out 0s;
	-ms-transition: all 0.4s ease-out 0s;
	-o-transition: all 0.4s ease-out 0s;
	transition: all 0.4s ease-out 0s;	
}
.w_overlay:hover {
	background:rgba(0,0,0,0.85);
}
.w_overlay:hover h3 {
	margin-top:10%;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.w_overlay:hover a {
	opacity:1;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}